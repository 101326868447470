<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE BOOK MONITORING (SUPPLY)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5">
              <v-flex xs12 md12>
                <v-select
                  v-model="category_id"
                  class="mx-2  mt-2"
                  dense
                  outlined
                  label="Category"
                  :items="category_items"
                  item-value="id"
                  item-text="category"
                  @change="selected_category"
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-select
                  v-model="bank_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.from_cheque_no+' - '+item.to_cheque_no }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers2"
                            :items="data_items2"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{
                      (item.ckv_id!=null?'CKV#'+item.ckv.voucher_no:(item.cv!=null?'CV#'+item.cv.voucher_no:(item.ftv!=null?'CKV#'+item.ftv.voucher_no:'')))
                      }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>

        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities, mdiCharity, mdiGift, mdiHandshakeOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      headers: [
        {text: 'SERIES', value: 'date_of_deposit', sortable: false},
      ],
      headers2: [
        {text: 'REQUEST ID', value: 'date_of_deposit', sortable: false},
        {text: 'DESCRIPTION', value: 'date_of_deposit', sortable: false},
        {text: 'VOUCHER #', value: 'date_of_deposit', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      bank_id: '',
      category_id: '',
      bank_items: [],
      category_items: [],
      tab: '',
      tabs: [
        {title: 'EXISTING', icon: mdiGoogleCirclesCommunities},
        {title: 'PENDING', icon: mdiAccountOutline},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cheque_book_series_pads', ['initialize_monitoring_supply',]),
      initialize_data() {
        this.initialize_monitoring_supply()
          .then(response => {
            this.bank_items = []
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category(value) {
        this.data_items = []
        this.data_items2 = []
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.bank_id = ''
          this.bank_items = this.category_items[index].bank_depository
        }
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = this.bank_items[index].cheque_book_series_pads
          this.data_items2 = this.bank_items[index].request_data_cheque_book
        }
      },
      selected_cheque(value) {
        var index = this.cheque_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = []
          this.from_cheque_no = this.cheque_items[index].from_cheque_no
          this.to_cheque_no = this.cheque_items[index].to_cheque_no
          for (var x = this.cheque_items[index].from_cheque_no; x <= this.cheque_items[index].to_cheque_no; x++) {
            this.data_items.push(x)
          }
        }
      }
    },
  }
</script>
